<template>
  <div v-if="pageEditorStore.page?.data || isAdmin">
    <template v-if="isAdmin">
      <div
        v-if="isFallbackLoaded"
        class="rounded-xl bg-amber-500 text-center p-4 py-2 sm:p-6 sm:py-3 md:p-8 md:py-4 text-white"
      >
        <div class="text-md font-bold">
          {{
            $t('admin.page_fallback_warning', { locale: locale.toUpperCase() })
          }}
        </div>
        <div class="text-sm">
          {{ $t('admin.admin_notification') }}
        </div>
      </div>
      <div
        v-if="pageEditorStore.isEditMode"
        class="rounded-xl bg-white p-4 sm:p-6 md:p-8 flex flex-col items-center gap-2 mb-5"
      >
        <div class="text-2xl font-bold text-center">
          {{ $t('admin.page_meta') }}
        </div>

        <template v-if="isShowMetaForm">
          <BaseForm
            :method="onSubmitMeta"
            :reset-on-submit="false"
            class="max-w-lg w-full py-4"
          >
            <div class="flex flex-col gap-4">
              <BaseInput
                v-model="metaForm.meta_title"
                name="meta_title"
                :disabled="isMetaProcessing"
                :label="$t('admin.meta_title')"
                :placeholder="metaTitle"
              />
              <BaseInput
                v-model="metaForm.meta_description"
                name="meta_description"
                :disabled="isMetaProcessing"
                :label="$t('admin.meta_description')"
                :placeholder="metaDescription"
              />
              <BaseInput
                v-model="metaForm.meta_keywords"
                name="meta_keywords"
                :disabled="isMetaProcessing"
                :label="$t('admin.meta_keywords')"
                :placeholder="
                  metaKeywords || $t('admin.meta_keywords_placeholder')
                "
              />

              <BaseImageInput
                :loading="isMetaProcessing"
                :max-size="3"
                :preview="metaForm.meta_image"
                :label="$t('admin.meta_image')"
                description="Ratio: 2x1, format: png, jpg, jpeg, svg, max size: 3MB"
                input-block-class="aspect-2/1"
                :accept="[
                  'image/png',
                  'image/jpg',
                  'image/jpeg',
                  'image/svg+xml',
                ]"
                @change="onMetaImageChange"
                @error:size="onSizeError"
              />

              <BaseButton
                :loading="isMetaProcessing"
                size="md"
                type="submit"
                class="self-center"
              >
                {{ $t('admin.save_page_meta') }}
              </BaseButton>
            </div>
          </BaseForm>

          <div class="text-gray-700 text-sm">
            {{ $t('admin.lang_warning') }}
          </div>
        </template>

        <BaseButton
          size="sm"
          look="link"
          @click="isShowMetaForm = !isShowMetaForm"
        >
          <span class="pr-2">
            {{ $t(`admin.${isShowMetaForm ? 'hide' : 'show'}_meta_form`) }}
          </span>
          <BaseIcon
            :name="
              isShowMetaForm
                ? 'outline_chevron_double_up'
                : 'outline_chevron_double_down'
            "
            size="xs"
          />
        </BaseButton>
      </div>
      <div class="!fixed bottom-4 right-4 z-50 flex gap-2">
        <BaseButton
          v-if="isDeletable"
          theme="destructive"
          :loading="pageEditorStore.isProcessing"
          @click="deletePage"
        >
          {{ $t('admin.delete_page', { locale: locale.toUpperCase() }) }}
        </BaseButton>
        <BaseButton
          v-if="pageEditorStore.isEditMode"
          theme="dark"
          :loading="pageEditorStore.isProcessing"
          @click="toggleEditMode"
        >
          {{ $t('admin.exit') }}
        </BaseButton>
        <div v-else-if="isFallbackLoaded" class="flex gap-3">
          <BaseButton
            :loading="pageEditorStore.isProcessing"
            @click="openFallbackPage"
          >
            {{ $t('admin.edit_fallback_page') }}
          </BaseButton>
          <BaseButton
            :loading="pageEditorStore.isProcessing"
            @click="() => toggleEditMode(true)"
          >
            {{
              $t('admin.create_language_version', {
                locale: locale.toUpperCase(),
              })
            }}
          </BaseButton>
        </div>
        <BaseButton
          v-else
          :loading="pageEditorStore.isProcessing"
          @click="toggleEditMode"
        >
          {{ $t('admin.edit_page') }}
        </BaseButton>
      </div>
    </template>
    <div>
      <template v-if="pageEditorStore.isEditMode && isAdmin">
        <client-only>
          <div>
            <PageEditorMenu
              v-if="isMenuOpen"
              @create="createBlock"
              @close="isMenuOpen = false"
            />
            <div
              class="border bg-white border-gray-500 p-6 flex items-center justify-center flex-col gap-3 rounded-xl mb-6"
            >
              <BaseButton size="sm" @click="isMenuOpen = true">
                {{ t('admin.open_block_menu') }}
              </BaseButton>
              <div class="text-gray-400 text-sm">
                {{ t('admin.open_menu') }}
              </div>
            </div>
            <div
              v-for="(component, index) in editorComponents"
              :key="component.uuid || component.id + index"
              class="list-group-item"
            >
              <PageEditorComponentsWrapper
                :element="component"
                @submit="onSubmit"
                @move:up="onMoveUp(index)"
                @move:down="onMoveDown(index)"
                @delete="onDelete(index, component.uuid)"
                @add="onAddBelow(index + 1)"
                @dublicate="onDublicate(index + 1, component)"
                @toggle:visibility="onToggleVisibility(component)"
                @select="checkIsLastVersion(component)"
              />
            </div>
          </div>
        </client-only>
      </template>
      <template v-if="!pageEditorStore.isEditMode">
        <div class="flex flex-col gap-5">
          <template
            v-for="(element, index) in pageEditorStore.editorBlocks || []"
            :key="element.id + index"
          >
            <component
              :is="element.viewComponent"
              v-if="element.is_visible"
              :block-id="element.id"
              :uuid="pageEditorStore.getBlockId(element)"
              :block-uuid="element.uuid"
              :form-data="element.editorData"
              @loaded="onElementLoaded"
            ></component>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { v4 as uuid } from 'uuid'
import { useI18n } from 'vue-i18n'
import type { EditorBlock } from '~/models/page-editor'
import { useAuth } from '~/stores/auth'
import { useNotifications } from '~/stores/notifications'
import { usePageEditor } from '~/stores/page-editor'

interface Props {
  pageLink: string
  isAdmin: boolean
  showError?: boolean
  eventId?: number
  metaTitle?: string
  metaKeywords?: string
  metaDescription?: string
  metaImage?: string
}

const props = withDefaults(defineProps<Props>(), {
  showError: false,
  eventId: undefined,
  metaTitle: '',
  metaDescription: '',
  metaImage: '',
  metaKeywords: '',
})

const { t, locale } = useI18n()
const authStore = useAuth()
const pageEditorStore = usePageEditor()
const route = useRoute()
const { pushNotification } = useNotifications()
const nuxtApp = useNuxtApp()
const { uploadMedia } = useMedia()

const isMenuOpen = ref(false)
const isMetaProcessing = ref(false)
const isShowMetaForm = ref(false)

// we use this ref for correct work of onAddBelow function
const newBlockIndex = ref<number | null>(null)

const editorComponents = ref<EditorBlock[]>([])

const isFallbackLoaded = computed(() => {
  return (
    !pageEditorStore.isProcessing &&
    pageEditorStore.page?.url &&
    pageEditorStore.page.url !== props.pageLink
  )
})

const isDeletable = computed(() => {
  return (
    pageEditorStore.isLoaded &&
    !pageEditorStore.isProcessing &&
    !pageEditorStore.isEditMode &&
    !isFallbackLoaded.value &&
    !pageEditorStore.editorBlocks.length &&
    locale.value !== 'en'
  )
})

const metaForm = reactive({
  meta_title: '',
  meta_description: '',
  meta_keywords: '',
  meta_image: '',
})

const initMetaForm = () => {
  metaForm.meta_title =
    pageEditorStore.page?.meta_title || props.metaTitle || ''
  metaForm.meta_description =
    pageEditorStore.page?.meta_description || props.metaDescription || ''
  metaForm.meta_keywords =
    pageEditorStore.page?.meta_keywords || props.metaKeywords || ''
  metaForm.meta_image =
    pageEditorStore.page?.meta_image || props.metaImage || ''
}

const createBlock = async (block: EditorBlock) => {
  try {
    if (!pageEditorStore.page?.id) {
      await pageEditorStore.createPage(props.pageLink)

      if (!pageEditorStore.page?.id) {
        return
      }
    }

    // for blocks that need eventId (Form, Program etc)
    if (props.eventId) {
      block.editorData.eventId = props.eventId
    }

    const newsPageBlock = await pageEditorStore.createPageBlock(
      block,
      pageEditorStore.page.id
    )
    const newEditorBlock = pageEditorStore.pageBlockToEditorBlock(newsPageBlock)

    if (newBlockIndex.value) {
      editorComponents.value.splice(newBlockIndex.value, 0, newEditorBlock)
    } else {
      editorComponents.value.push(newEditorBlock)
    }
    newBlockIndex.value = null
    pushNotification({
      title: t(`notifications.editor.create_success`),
    })
    await updateSort(false)
  } catch (error) {
    pushNotification({
      title: t(`notifications.editor.create_error`),
      theme: 'destructive',
    })
    useLogError(error)
  }
}

function openFallbackPage() {
  if (pageEditorStore.page?.url) {
    navigateTo(new URL(pageEditorStore.page.url).pathname)
    resetPage()
  }
}

function onToggleVisibility(block: EditorBlock) {
  block.is_visible = !block.is_visible
  updateBlock(block)
}

function onAddBelow(index: number) {
  newBlockIndex.value = index
  isMenuOpen.value = true
}

async function onDublicate(index: number, block: EditorBlock) {
  newBlockIndex.value = index

  const newBlockData = reactive({
    ...block,
    editorData: useCloneDeep(block.editorData),
    key: Symbol('key'),
    uuid: uuid(),
  })

  await createBlock(newBlockData)
}

const removeTempKeys = (data: any): any => {
  if (typeof data !== 'object' || data === null) {
    return data
  }

  if (Array.isArray(data)) {
    return data.map((item) => removeTempKeys(item))
  }

  return Object.keys(data).reduce((acc, key) => {
    if (!key.startsWith('tmp_')) {
      acc[key] = removeTempKeys(data[key])
    }
    return acc
  }, {} as any)
}

const onSubmit = async (formData: any, block: EditorBlock) => {
  const isLastVersion = await checkIsLastVersion(block)

  if (!isLastVersion) {
    return
  }

  // remove keys that start with "tmp_"
  const cleanedFormData = removeTempKeys(formData.value)

  Object.assign(block.editorData, useCloneDeep(cleanedFormData))

  await updateBlock(block)
}

const updateBlock = async (block: EditorBlock) => {
  try {
    const data = await pageEditorStore.updatePageBlock(block)
    // otherwise we will get the error in checkIsLastVersion on the next click on the block because content in the block is old
    block.content = data.content
    pushNotification({
      title: t(`notifications.editor.update_success`),
    })
  } catch (error) {
    pushNotification({
      title: t(`notifications.editor.update_error`),
      theme: 'destructive',
    })
    useLogError(error)
  }
}

const onMoveUp = async (index: number) => {
  if (index > 0) {
    const element = editorComponents.value.splice(index, 1)
    editorComponents.value.splice(index - 1, 0, element[0])
  }
  await updateSort()
}

const onMoveDown = async (index: number) => {
  if (index < editorComponents.value.length) {
    const element = editorComponents.value.splice(index, 1)
    editorComponents.value.splice(index + 1, 0, element[0])
  }
  await updateSort()
}

const onDelete = async (index: number, uuid: string) => {
  editorComponents.value.splice(index, 1)

  try {
    await pageEditorStore.deletePageBlock(uuid)
    pushNotification({
      title: t(`notifications.editor.delete_success`),
    })

    await updateSort(false)
  } catch (error) {
    pushNotification({
      title: t(`notifications.editor.delete_error`),
      theme: 'destructive',
    })
    useLogError(error)
  }
}

const updateSort = async (isShowNotification = true) => {
  try {
    await pageEditorStore.updateSort(editorComponents.value)
    isShowNotification &&
      pushNotification({
        title: t(`notifications.editor.sort_success`),
      })
  } catch (error) {
    isShowNotification &&
      pushNotification({
        title: t(`notifications.editor.sort_error`),
        theme: 'destructive',
      })
    useLogError(error)
  }
}

// check if the last version of the blocks matches the user's one
const checkIsLastVersion = async (block: EditorBlock): Promise<boolean> => {
  try {
    const lastVersion = await pageEditorStore.getPageBlock(
      block.uuid,
      authStore.isAdmin
    )

    if (block.content === lastVersion.content) {
      return true
    } else {
      pushNotification({
        title: t(`notifications.editor.not_last_error`),
        theme: 'destructive',
      })

      block.content = lastVersion.content
      block.editorData = JSON.parse(lastVersion.content)
      return false
    }
  } catch (error: any) {
    if (error.statusCode === 404) {
      pushNotification({
        title: t(`notifications.editor.no_block_error`),
        theme: 'destructive',
      })
    } else {
      useLogError(error)
    }
    return false
  }
}

function resetPage() {
  pageEditorStore.resetPage()
  editorComponents.value = []
}

async function deletePage() {
  if (!pageEditorStore.page?.id) {
    throw new Error('Page id is not defined on delete')
  }
  try {
    await pageEditorStore.deletePage(pageEditorStore.page.id)
    resetPage()
    await pageEditorStore.fetchPage(
      props.pageLink,
      false,
      false,
      props.showError
    )
    pushNotification({
      title: t(`notifications.editor.delete_page_success`),
    })
  } catch (error: any) {
    pushNotification({
      title: t(`notifications.editor.delete_page_error`),
      theme: 'destructive',
    })
  }
}

watch(
  () => authStore.isAdmin,
  () => {
    if (pageEditorStore.isEditMode) {
      toggleEditMode(true)
    }
  }
)

const toggleEditMode = async (reset = false) => {
  reset === true && resetPage()
  pageEditorStore.toggleEditMode()

  // fetch last version of the page to see all updates after toggle edit mode
  await pageEditorStore.fetchPage(
    props.pageLink,
    pageEditorStore.isEditMode ? authStore.isAdmin : false,
    false,
    props.showError
  )
  editorComponents.value = [...pageEditorStore.editorBlocks]

  // we want to warn people that the content of the page has changed
  if (pageEditorStore.isEditMode) {
    try {
      await pageEditorStore.fetchBlockTemplateList()
    } catch (error) {
      pushNotification({
        title: t(`notifications.editor.fetch_template_list_error`),
        theme: 'destructive',
      })
      useLogError(error)
    }

    if (
      editorComponents.value.some(
        (item, index) =>
          item.content !== pageEditorStore.editorBlocks[index]?.content
      )
    ) {
      pushNotification({
        title: t(`notifications.editor.content_updated`),
        theme: 'primary',
      })
    }
  }
}

// meta
const onSubmitMeta = async () => {
  isMetaProcessing.value = true
  try {
    if (!pageEditorStore.page?.id) {
      await pageEditorStore.createPage(props.pageLink)

      if (!pageEditorStore.page?.id) {
        return
      }
    }

    await pageEditorStore.updatePage(pageEditorStore.page.id, metaForm)
    pushNotification({
      title: t(`notifications.editor.update_page_meta_success`),
    })
  } catch (error) {
    pushNotification({
      title: t(`notifications.editor.update_page_meta_error`),
      theme: 'destructive',
    })
  } finally {
    isMetaProcessing.value = false
  }
}

async function onMetaImageChange(file: File) {
  try {
    isMetaProcessing.value = true

    metaForm.meta_image = await uploadMedia(file)

    pushNotification({
      title: 'Image was successfully uploaded',
    })
  } catch (error: any) {
    pushNotification({
      title: 'Error on image upload',
      theme: 'destructive',
    })
    useLogError(error)
  } finally {
    isMetaProcessing.value = false
  }
}

function onSizeError() {
  pushNotification({
    title: t('notifications.editor.file_size_error', 3),
    theme: 'destructive',
  })
}

const initMeta = () => {
  nuxtApp.runWithContext(() => {
    useHeadMeta({
      title: pageEditorStore.page?.meta_title || props.metaTitle,
      description:
        pageEditorStore.page?.meta_description || props.metaDescription,
      keywords:
        pageEditorStore.page?.meta_keywords ||
        props.metaKeywords ||
        `event, WN, ${props.metaTitle}`,
      image: pageEditorStore.page?.meta_image || props.metaImage,
    })
  })
}

onBeforeMount(async () => {
  if (
    !nuxtApp.isHydrating &&
    props.isAdmin !== pageEditorStore.isLoadedByAdmin
  ) {
    resetPage()
  }

  await pageEditorStore.fetchPage(props.pageLink, false, true, props.showError)
  editorComponents.value = [...pageEditorStore.editorBlocks]

  initMeta()

  if (authStore.isAdmin) {
    initMetaForm()
  }
})

onServerPrefetch(async () => {
  await pageEditorStore.fetchPage(props.pageLink, false, true, props.showError)
  editorComponents.value = [...pageEditorStore.editorBlocks]

  initMeta()

  if (authStore.isAdmin) {
    initMetaForm()
  }
})

onUnmounted(() => {
  resetPage()
  pageEditorStore.turnOffEditMode()
})

const onElementLoaded = (el: Ref<HTMLElement>, blockName: string) => {
  if (el.value && route.query?.scroll === blockName) {
    el.value.scrollIntoView({ behavior: 'smooth' })
  }
}
</script>
